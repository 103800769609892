@import '~antd/dist/antd.dark.css';
@import '~antd/dist/antd.compact.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Matter',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/Hellix/Hellix-Regular.ttf");
  src: url("./assets/fonts/Hellix/Hellix-Bold.ttf");
  src: url("./assets/fonts/Hellix/Hellix-SemiBold.ttf");
  src: url("./assets/fonts/Hellix/Hellix-Medium.ttf");
  src: url("./assets/fonts/Hellix/Hellix-Light.ttf");
}

input,
.ant-select-selector,
.ant-input-affix-wrapper {
  border-radius: 6px !important;
}

.primaryBtn{
  background-color: #00A8CC;
  color: white;
  border-radius: 6px;
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  height: auto;
  font-size: 15px;
  font-weight: 500;
}
.primaryBtn:hover{
  background-color: #00A8CC;
  color: white;
}


#menu-container .ant-menu-inline.ant-menu-root .ant-menu-item > *{
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 14px;
}
#menu-container .ant-menu-inline > li {
  margin: 25px 0 !important;
  border-left: 5px solid transparent;
}
#menu-container .ant-menu-item-selected , #menu-container .selected{
  border-left-color: #00A8CC !important;
}
#menu-container #site-layout .site-layout-background {
  background: #fff;
}
#menu-container #site-layout {
  z-index: 1;
  transition: 0.2s;
}
#menu-container .ant-layout-sider-has-trigger{
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
#menu-container .ant-layout-sider-has-trigger.ant-layout-sider-collapsed{
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.ant-layout-header {
  background-color: #fff;
}
.sub-container {
   background: #fff;
   border-radius: 7px;
   padding: 10px;
}
.slidermenu .ant-layout-sider-children{
  background: #1E2A4E;
}
.explorics-sider-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
}
.slidermenu .ant-menu-item a{
  color: white !important;
}
.site-layout-background .trigger{
  background-color: #1E2A4E;
  padding: 10px 3px;
  color: white;
  border-radius: 0 4px 4px 0;
}
.explorics-sider-menu .ant-menu-inline-collapsed li.ant-menu-item:nth-child(1){
  margin-top: 20px;
}
.explorics-sider-menu .ant-menu-inline-collapsed li.ant-menu-item{
  height: 46px !important;
  padding: 0 calc(50% - 34px / 2) !important;
}
.document-table th.ant-table-cell{
  background-color: #DFF4F9;
  font-weight: 600;
}
.document-table .ant-checkbox-inner{
  border-radius: 100px !important;
}
.document-table .ant-checkbox-checked .ant-checkbox-inner{
  background-color: #00A8CC !important;
  border-color: #00A8CC !important;
}
.document-table th .ant-checkbox-wrapper{
  display: none !important;
}
.document-table .ant-table-tbody > tr.ant-table-row-selected > td{
  background-color: #f9fdff !important;
}
.editDocument .ant-drawer-header {
  background: #EFF4F9;
}
.editDocument .ant-drawer-header-title{
  flex-direction: row-reverse !important;
}
th.ant-table-cell {
  font-size: 14px;
}
.ant-modal-confirm-btns .ant-btn.ant-btn-primary{
  background-color: #00A8CC;
  border-color: #00A8CC;
  color: white;
  border-radius: 7px;
  width: 60px;
}
.ant-modal-confirm-btns .ant-btn.ant-btn-default{
  background-color: transparent;
  border-color: #00A8CC;
  color: #00A8CC;
  border-radius: 7px;
  width: 60px;
}
.ant-modal-content{
  border-radius: 15px;
}

.projects .card{
  background: #FFFFFF;
  border: 1px solid #E3E4E9;
  border-radius: 8px;
  margin: 20px 0;
  padding: 28px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projects .card.active{
  background: #F9FCFD;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #00A8CC;
}
.projects .card h2{
  font-weight: 600;
  margin-bottom: 15px;
}
.projects .card h1{
  font-size: 45px;
  margin-bottom: 0;
  font-weight: bolder;
}
.projects .card button{
  background-color: #00A8CC;
  color: white;
  border-color: #00A8CC;
  border-radius: 7px;
  font-size: 16px;
  height: auto;
}

.lists .ant-collapse-item-active .ant-collapse-header{
  border-bottom: 1px solid #BFE9F2;
}
.lists .ant-collapse-item{
  background-color: white;
  border: 0;
  margin-bottom: 20px;
  border-radius: 8px;
}
.lists{
  background-color: transparent !important;
}
.lists .ant-collapse-arrow{
  color: #00A8CC;
}
.lists .ant-collapse-content{
  padding: 10px;
}

.searchbar{
  position: relative;
  margin-right: 10px;
}
.searchbar input{
  padding-left: 25px !important;
}
.searchbar .ant-input-group-addon{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 99;
}
.searchbar .ant-input-group-addon button{
  border: none;
  border-radius: 10px !important;
  background-color: transparent;
}

.collaps .ant-spin-container{
  padding: 10px !important;

}

.hiddenCol{
  display: none !important;
}
.ant-table-cell-row-hover{
  background-color: transparent !important
}